import MODALS from './modals';

const DATA = {
  SIGN_UP: {
    title: 'Sign Up',
  },
  SIGN_UP_FOR_VOTING: {
    title: 'Sign up to join the list!',
    description: 'Joining a waitlist is currently for U.S., Canada, UK, Australia, and New Zealand residents only.',
    scope: MODALS.SCOPES.VOTING,
  },
  SIGN_UP_FOR_WAITLIST: {
    title: 'Thank you for joining our waitlist!',
    scope: MODALS.SCOPES.WAITLIST,
  },
  SIGN_UP_FOR_COMMENTING: {
    title: 'We need your info to give you that awesome pre-retail discount.',
    description: "Make sure to share with your friends so this product meets it's goal and enters the pre-order phase!",
    scope: MODALS.SCOPES.COMMENTING,
  },
  SIGN_UP_FOR_INNOVATION: {
    title: 'Please sign up so we can be in touch.',
    description: 'Thank you so much for wanting to co-create with us. One of our team members will reach out shortly about your product submission.',
  },
  SIGN_UP_FOR_PRODUCT_SUBSCRIPTION: {
    title: 'One or more subscription items are in your cart.',
    description: 'Please login in or create an account to proceed.',
    scope: MODALS.SCOPES.PRODUCT_SUBSCRIPTION,
  },
  SIGN_IN: {
    title: 'Sign In',
  },
  SIGN_IN_FOR_VOTING: {
    title: 'Log in to join the list!',
    scope: MODALS.SCOPES.VOTING,
  },
  SIGN_IN_FOR_COMMENTING: {
    title: 'Log in for comment access!',
    scope: MODALS.SCOPES.COMMENTING,
  },
  SIGN_IN_FOR_PRODUCT_SUBSCRIPTION: {
    title: 'One or more subscription items are in your cart.',
    description: 'Please login in or create an account to proceed.',
    scope: MODALS.SCOPES.PRODUCT_SUBSCRIPTION,
  },
  FORGOT_PASSWORD: {
    title: 'Forgot your password?',
    description: "We'll email you instructions to reset it.",
  },
  FORGOT_PASSWORD_FOR_VOTING: {
    title: 'Forgot your password?',
    description: "We'll email you instructions to reset it.",
    scope: MODALS.SCOPES.VOTING,
  },
  FORGOT_PASSWORD_FOR_COMMENTING: {
    title: 'Forgot your password?',
    description: "We'll email you instructions to reset it.",
    scope: MODALS.SCOPES.COMMENTING,
  },
  FORGOT_PASSWORD_FOR_PRODUCT_SUBSCRIPTION: {
    title: 'Forgot your password?',
    description: "We'll email you instructions to reset it.",
    scope: MODALS.SCOPES.PRODUCT_SUBSCRIPTION,
  },
  FORGOT_PASSWORD_SENT: {
    title: 'Forgot your password?',
    subtitle: 'We just sent you an email with instructions to reset it.',
  },
  RESEND_SIGN_UP_CONFIRMATION: {
    title: 'Uh oh!',
    description: "Looks like you're missing out! You haven't confirmed your email address yet. Press the button to resend email and confirm your account.",
  },
  RESEND_SIGN_UP_CONFIRMATION_FOR_VOTING: {
    title: "You're almost done!",
    description: "We just sent you a link to confirm your email address. Once you confirm, your support will be counted and you'll receive your 15% off code.",
  },
  RESEND_SIGN_UP_CONFIRMATION_FOR_COMMENTING: {
    title: 'Uh oh!',
    description: "Leaving comment is exclusive to our confirmed members and you haven't confirmed your email address yet. Press the button to resend and email and confirm your account.",
  },
  SIGN_UP_CONFIRMATION: {
    title: 'Thanks for joining us!',
    description: 'Check your email for the next steps and to unlock your code.',
  },
  SIGN_UP_CONFIRMATION_FOR_VOTING: {
    title: "You're almost there!",
    description: 'We just sent you a link to confirm your email address. Once you confirm, you will be added to the list and you will receive 15% off your next order.',
  },
  SIGN_UP_CONFIRMATION_FOR_COMMENTING: {
    title: "You're almost there!",
    description: 'We just sent you a link to confirm your email address. Once you confirm, your comment will be submitted.',
  },
  CAMPAIGN_NOT_WIN: {
    title: 'Unfortunately, this campaign did not win.',
    description: 'Check out other winning campaigns now available for purchase.',
  },
  VOTING: {
    title: "You're on the list!",
    description: 'This product is one step closer to becoming a reality.<br>In the meantime, check out some of our bestsellers.',
  },
  QUIZ_FINISHED: {
    color: '#DBAEB9',
  },
  INNOVATOR_INFO_SUBMITTED: {
    color: '#DBAEB9',
  },
  REMOVE_CREDIT_CARD: {
    title: 'Are you sure you want to remove this card?',
    description: 'This action cannot be undone.',
  },
  CANCEL_SUBSCRIPTION: {
    title: 'Are you sure you want to cancel your subscription?',
  },
  WELCOME_PROMOTION: {
    title: "You're in!",
  },
  WELCOME_PROMOTION_FROM_VOTING: {
    title: "It's official!",
    description: "You're on the list & your pre-retail price is locked in!",
  },
  REGIMEN_SENT: {
    title: 'We’ve sent your regimen!',
    description: 'Check your email for a copy of this customized regimen.',
  },
  REDEEM_GIFT_CARD: {
  },
  GIFT_CARD_REDEEMED: {
    title: 'Your Gift Card was successfully activated!',
  },
  CHECK_OUT_THE_LOUNGE: {
    title: 'Welcome to The Lounge!',
    showButton: true,
  },
  JOIN_THE_LOUNGE_FOR_VOTING: {
    title: 'MEMBERS ONLY',
    description: 'Lounge members earn <span>50 points</span> when<br>joining a waitlist!',
    scope: MODALS.SCOPES.VOTING,
  },
  JOIN_THE_LOUNGE_FOR_PREORDERING: {
    title: 'Reward your skincare obsession!',
    description: 'You could earn <span>100 points</span> for this pre-order when you join The Lounge',
    scope: MODALS.SCOPES.PREORDERING,
  },
  EXTENDED_SIGN_UP: {
    title: 'Lounge Members get exclusive access to waitlists, points for purchase, and so much more!',
  },
  EXTENDED_SIGN_UP_FOR_VOTING: {
    title: 'Lounge Members get exclusive access to waitlists, points for purchase, and so much more!',
    scope: MODALS.SCOPES.VOTING,
  },
};

export default DATA;
